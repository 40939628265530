//Superadmin > User Access
export const USER_GROUP_LABEL = "User Group"
export const USER_ACCOUNT_ACCESS_LABEL = "User Account & Access"
export const DUPLICATE_USER_PERMISSION_LABEL = "Duplicate User Permission"

//Dashboard >> Main
export const PRINT_JOB_STATUSES_LABEL = "Print Job Statuses"
export const APPROVAL_NOTIFICATIONS = "Approval Notifications"
export const QUICK_ACCESS = "Quick Access"

//System Setup >> Organisation
export const ORGANISATION_TAB_LABEL = "Organisation"
export const BRANCH_TAB_LABEL = "Branch"
//System Setup >> Employee
export const JOB_POSITION_LABEL = "Job Position"
export const EMPLOYEE_MASTER_LABEL = "Employee Master"
export const CUSTOMER_REASSIGNMENT_LABEL = "Customer Reassignment"
export const ITEM_BRAND_AND_ITEM_GROUP_ASSIGNMENT_LABEL = "Item Brand & Group Assignment"
export const SUPERIOR_ASSIGNMENT_LABEL = "Superior Assignment"
//System Setup >> Customer
export const CUSTOMER_MASTER_LABEL = "Customer Master"
export const CUSTOMER_TYPE_LABEL = "Customer Type"
export const CUSTOMER_GROUP_LABEL = "Customer Group"
export const CUSTOMER_OUTLET_LABEL = "Customer Outlet"
export const BRAND_TIER_LABEL = "Brand Tier"
//System Setup >> Supplier
export const SUPPLIER_TYPE = "Supplier Type"
export const SUPPLIER_GROUP = "Supplier Group"
export const SUPPLIER_MASTER = "Supplier Master"
export const GENERAL_PAYEE_MASTER = "General Payee Master"
//System Setup >> Bank
export const BANK_GROUP_TAB_LABEL = "Bank Group"
export const BANK_BRANCH_TAB_LABEL = "Bank Branch"
export const BANK_ACCOUNT_TAB_LABEL = "Bank Account"
export const PAYMENT_MODE = "Payment Mode"
export const PRODUCT_GROUP_TAB_LABEL = "Product Group"
export const BULK_PAYMENT_SETUP_TAB_LABEL = "Bulk Payment Setup"
//System Setup >> Setting
export const HEADER_FOOTER_TAB_LABEL = 'Header & Footer'
export const TNC_TAB_LABEL = 'Terms & Conditions'
export const TAX_CODE_LABEL = 'Tax Setup'
export const TAX_TYPE_LABEL = "Tax Type"
export const UOM_LABEL = 'Unit of Measurement'
export const DELIVERY_TYPE_LABEL = 'Delivery Type'
export const CREDIT_TERMS_LABEL = 'Credit Term'
export const PRINTER_LABEL = 'Printer'
export const APPROVAL_SETTINGS_LABEL = "Approval Settings"
//System Setup >> Control Setup
export const INVENTORY_CONTROL_LABEL = "Inventory Control"
export const SO_CONTROL_LABEL = "SO Control"
export const AR_CONTROL_LABEL = "AR Control"
export const AP_CONTROL_LABEL = "AP Control"
export const GL_CONTROL_LABEL = "GL Control"
export const DEALER_PORTAL_CONTROL_LABEL = "Dealer Portal Control"
//System Setup >> Report
export const GST_TAX_LISTING = "GST Tax Listing"
export const CUSTOMER_MASTER_LIST = "Customer Master Listing"
export const TERM_LIMIT = "Customer Master - Term & Limit"
export const SUPPLIER_MASTER_LISTING = "Supplier Master Listing"
export const GENERAL_PAYEE_MASTER_LISTING = "General Payee Master Listing"

//Sales >> Maintenance
export const COST_CONTROL_LABEL = "Cost Control"
export const RETURN_REASON_LABEL = "Return Reason"
export const REBATE_LABEL = "Rebate"
export const TAX_LABEL = "Tax"
export const CURRENCY_EXCHANGE_RATE_LABEL = "Currency Exchange Rate"
export const ORDER_SOURCE_LABEL = "Order Source"
export const BUNDLE_PACKAGE_LABEL = "Bundle / Package"
export const INVOICE_REASSIGNMENT_LABEL = "Invoice Reassignment"
export const SO_SETUP_LABEL = "SO Setup"
export const SO_PRINTER_CONTROL_LABEL = "SO Printer Control"
//Sales >> Inquiry
export const SO_STATUS_LABEL = "Sales Order Status"
export const DEALER_TRANSFER_LOG_LABEL = "Dealer Transfer Log"
export const DEALER_TERM_AND_CREDIT_LABEL = "Dealer Terms & Credit"
export const CREDIT_REVIEW_HISTORY_LABEL = "Credit Review History"
//Sales >> Transactions
export const PRE_SALES_QUOTATION_LABEL = "Pre-Sales Quotation"
export const SALES_QUOTATION_LABEL = "Sales Quotation"
export const SALES_ORDER_LABEL = "Sales Order"
export const SALES_ORDER_BRANCH_LABEL = "Sales Order (Branch)"
export const SALES_RETURN_LABEL = "Sales Return"
export const CREDIT_REVIEW_LABEL = "Credit Review"
export const PRICE_REVIEW_LABEL = "Price Review"
export const INVOICE_MANUAL_LABEL = "Invoice (Manual)"
export const ITEM_PICKING_LABEL = "Item Picking"
export const ITEM_PICKING_BRANCH_LABEL = "Item Picking (Branch)"
//Sales >> Report
export const PSQ_LABEL = "PSQ Summary"
export const SALES_DISTRIBUTION_LABEL = "Sales Distribution"
export const DAILY_SUMMARY_SALES_LISTING_LABEL = "Daily Summary Sales Listing"
export const PSI_REPORT_LABEL = "PSI Report"
export const SALES_IMEI_REPORT_LABEL = "Sales IMEI Report"
export const SALES_BY_CUSTOMER_LABEL = "Sales by Customer"
export const SO_PROCESS_DETAIL_LABEL = "Sales Order Process Detail Report"
export const SALES_PAYMENT_DURATION_REPORT_LABEL = "Sales Payment Duration Report"
export const IMEI_LISTING_LABEL = "IMEI Listing";
export const CREDIT_TERMS_LIMIT_REVIEW_REPORT_LABEL = "Credit Terms & Limit Review Report"
export const SALES_BY_MODEL_LABEL = "Sales by Model";
export const SALES_DISTRIBUTION_BY_MODEL_LABEL = "Sales Distribution by Model";
export const SAMSUNG_SALES_DISTRIBUTION_LABEL = "Samsung Sales Distribution";


//Commission >> Maintenance
export const COMMISSION_SETUP_LABEL = "Commission Setup"
export const COMMISSION_SCHEDULE_LABEL = "Commission Schedule"
export const COMMISSION_MODEL_GROUP_LABEL = "Commission Model Group"
//Commission >> Reports
export const COMMISSION_DETAIL_LABEL = "Commission Detail"
export const PAYMENT_COLLECTED_LABEL = "Payment Collected Invoice"
export const ACTIVE_DEALER_DETAIL_LABEL = "Active Dealer Detail"
export const ACTIVE_DEALER_BY_BRAND_LABEL = "Active Dealer By Brand"

//Purchase >> Transactions
export const PO_LABEL = "PO"
export const GRN_LABEL = "GRN"
export const GRO_LABEL = "GRO"
export const UPDATE_GRN_STATUS_LABEL = "Update GRN Status"

//Inventory >> Maintenance
export const ITEM_TAB_LABEL = "Item Master"
export const ITEM_BRAND_TAB_LABEL = "Item Brand"
export const ITEM_GROUP_TAB_LABEL = "Item Group"
export const ITEM_MODEL_TAB_LABEL = "Item Model"
export const ITEM_ACCESSORY_TAB_LABEL = "Item Accessory"
export const PRODUCT_EXCHANGE_LABEL = "Product Exchange"
export const PRODUCT_REPLACEMENT_LABEL = "Product Replacement"
export const ITEM_RECLASSIFICATION_LABEL = "Item Reclassification"
export const PHYSICAL_ADJUSTMENT_LABEL = "Physical Adjustment"
//Inventory >> Inquiry
export const INVENTORY_STATUS_LABEL = "Inventory Status"
export const PRODUCT_STATUS_LABEL = "Product Status"
//Inventory >> Transactions
export const ITEM_ON_LOAN_LABEL = "Item On Loan"
export const ITEM_ON_LOAN_SALES_LABEL = "Item On Loan Sales"
export const ITEM_ON_LOAN_RETURN_LABEL = "Item On Loan Return"
export const ITEM_RESERVE_LABEL = "Item Reserve"
export const LOCATION_TRANSFER_OUT_LABEL = "Location Transfer Out"
export const LOCATION_TRANSFER_IN_LABEL = "Location Transfer In"
//Inventory >> Processing
export const IN_POSTING_LABEL = "IN Posting"
export const IN_UNPOSTING_LABEL = "IN Un-Posting"
//Inventory >> reports
export const TRANSIT_REPORT_LTO_LABEL = "Transit Report (LTO)";
export const INVENTORY_MOVEMENT_REPORT_LABEL = "Inventory Movement Report";
export const INVENTORY_MOVEMENT_REPORT_EXTRA_LABEL = "Inventory Movement Report Extra";
export const INVENTORY_MOVEMENT_REPORT_CONSOLIDATE_LABEL = "Inventory Movement Report Consolidate";
export const INVENTORY_MOVEMENT_REPORT_WITH_COST_LABEL = "Inventory Movement Report With Cost";
export const STOCK_TAKE_REPORT_LABEL = "Stock Take Report";
export const ITEM_ON_LOAN_LISTING_LABEL = "Item On Loan List";
export const ITEM_MASTER_DETAIL_LISTING_LABEL = "Item Master Detail Listing";
export const STOCK_CLOSING_REPORT_LABEL = "Stock Closing Report";
export const ITEM_RESERVATION_LABEL = "Item Reservation Report";
export const OUTSTANDING_GRN_LISTING_LABEL = "Outstanding GRN List";
export const INVENTORY_JOURNAL_LISTING_LABEL = "Inventory Journal Listing";
export const GRO_CN_OUTSTANDING_LIST_LABEL = "GRO CN Outstanding List";
export const GRN_DETAIL_REPORT_LABEL = "GRN Detail Report";
export const STOCK_PSQ_INVENTORY_LABEL = "Stock & PSQ Inventory";

// Delivery >> Maintenance
export const DELIVERY_REASSIGNMENT_LABEL = "Delivery Reassignment"
export const COURIER_MASTER_LABEL = "Courier Master"
export const POSTCODE_CONTROL = "Postcode Control"
export const YTL_INTEGRATION_SETTING_LABEL = "YTL Integration Settings"
export const YTL_BRANCH_CONFIG_LABEL = "YTL Branch Configuration"
// Delivery >> Transactions
export const CONSIGNMENT_NOTE_LABEL = "Consignment Note"
export const MANUAL_CONSIGNMENT_LABEL = "Consignment Note (M)"
export const DESPATCH_ASSIGNMENT_LABEL = "Despatch Assignment"
export const COURIER_COLLECTION_LABEL = "Courier Collection"
export const PARCEL_NOTE_LABEL = "Parcel Note"
// Delivery >> Inquiry
export const DELIVERY_STATUS_LABEL = "Delivery Status"
export const DESPATCH_DO_INFO_LABEL = "Despatch DO Info"
export const COURIER_DO_INFO_LABEL = "Courier DO Info"
//Delivery >> Reports
export const MCMC_REPORT_LABEL = "MCMC Report"
export const DO_DETAIL_REPORT_LABEL = "DO Detail Report"
export const DO_DETAIL_REPORT_BY_ITEM_LABEL = "DO Detail Report By Item"
export const PARCEL_DETAIL_LOG_LABEL = "Parcel Detail Log"
export const HIGH_VALUE_ITEM_CONTROL_LABEL = "High Value Item Control"
export const COURIER_DETAIL_REPORT_LABEL = "Courier Detail Report"
export const DO_PRODUCT_LABEL = "DO Product"

//General Ledger >> Maintenance
export const SERVICE_ITEM_LABEL = "Service Item"
export const GST_LABEL = "GST Period"
export const SST_LABEL = "SST Period"
export const COA_LABEL = "COA"
export const ACCOUNT_ANALYSIS_LABEL = "Account Analysis"
export const ACCOUNT_GROUP_LABEL = "Account Group"
export const FINANCIAL_YEAR_LABEL = "Financial Year"
//General Ledger >> Transactions
export const GENERAL_PAYMENT_LABEL = "General Payment"
export const GENERAL_JOURNAL_LABEL = "General Journal"
export const GP_BP_APPROVAL_LIST_LABEL = "GP BP Approval List"
//General Ledger >> Processing
export const GL_POSTING_LABEL = "Posting"
export const GL_UNPOSTING_LABEL = "Unposting"
export const YEAR_END_PROCESSING_LABEL = "Year End Processing"
export const PRIOR_YEAR_END_CLOSING_ADJUSTMENT_LABEL = "Prior Year End Closing Adjustment"
export const INVENTORY_LTO_POSTING_LABEL = "Inventory LTO - Posting"
export const INVENTORY_LTO_UNPOSTING_LABEL = "Inventory LTO - Unposting"
//General Ledger >> reports
export const TAX_LISTING_LABEL = "Tax Listing (GST/SST)"
export const GST_SUMMARY_REPORT_LABEL = "GST Summary Report/GST 03/GST Audit"
export const GL_GENERAL_LEDGER_LABEL = "GL General Ledger"
export const GL_JOURNAL_LIST_LABEL = "GL Journal List"
export const TRIAL_BALANCE_LABEL = "Trial Balance"
export const GST_GL_LISTING_LABEL = "GST GL Listing"
export const GP_BULK_PAYMENT_STATUS_REPORT_LABEL = "GP Bulk Payment Status Report"
export const FINANCIAL_STATEMENT_REPORT_LABEL = "Financial Statement Report"
export const CHART_OF_ACCOUNT_LABEL = "Chart of Account List"
export const GP_SUMMARY_TRANSACTION_LIST_LABEL = "GP Summary Transaction List"

//Account Receivable >> Maintenance
export const CN_TYPE_LABEL = "CN Type"
export const DN_TYPE_LABEL = "DN Type"
//Account Receivable >> Inquiry
export const AR_TRANSACTION_INFO_LABEL = "AR Transaction Info"
//Account Receivable >> Transactions
export const AR_INVOICE_LABEL = "AR Invoice"
export const AR_INVOICE_SST_LABEL = "AR Invoice (SST)"
export const BATCH_CN_REQUISITION_LABEL = "Batch CN Requisition"
export const BATCH_DN_REQUISITION_LABEL = "Batch DN Requisition"
export const CN_REQUISITION_LABEL = "CN Requisition"
export const CREDIT_NOTE_LABEL = "Credit Note"
export const DN_REQUISITION_LABEL = "DN Requisition"
export const DEBIT_NOTE_LABEL = "Debit Note"
export const AR_RECEIPT_LABEL = "Receipt"
export const ADVANCED_OFFSET_LABEL = "AR Payment / CN Offset"
export const CASH_BOOK_RECONCILIATION_LABEL = "Cash Book Reconciliation"
export const BAD_DABT_RELIEF_LABEL = "Bad Debt Relief"
export const BAD_DEBT_RECOVER_LABEL = "Bad Debt Recovery"
//Account Receivable >> Processing
export const AR_POSTING_LABEL = "AR Posting"
export const AR_UN_POSTING_LABEL = "AR Un-Posting"
//Account Receivable >> Report
export const CUSTOMER_STATEMENT_LABEL = "Customer Statement";
export const CUSTOMER_STATEMENT_OFFSET_LABEL = "Customer Statement Offset";
export const CUSTOMER_TRANSACTION_NOTE_LABEL = "Customer Transaction Note";
export const CUSTOMER_AGING_LABEL = "Customer Aging";
export const CP_58_REPORT_LABEL = "CP 58 Report";
export const CN_APPROVAL_LIST_LABEL = "CN Approval List";
export const CN_STATUS_REPORT_LABEL = "CN Status Report";
export const DN_APPROVAL_LIST_LABEL = "DN Approval List";
export const DN_STATUS_REPORT_LABEL = "DN Status Report";
export const GST_SUPPLY_LISTING_LABEL = "GST Supply Listing";
export const OUTPUT_TAX_LISTING_LABEL = "Output Tax Listing";
export const CUSTOMER_TRANSACTION_LIST_LABEL = "Customer Transaction List";
export const CUSTOMER_SUMMARY_TRANSACTION_LIST_LABEL = "Customer Summary Transaction List";
export const CUSTOMER_OVERDUE_LABEL = "Customer Overdue";
export const ROUDING_ADJ_LIST_LABEL = "Rounding Adj List";
export const CUSTOMER_LEDGER_LABEL = "Customer Ledger";
export const CN_DN_LISTING_LABEL = "CN/ DN Listing";
export const DEALER_CREDIT_CONTORL_LABEL = "Dealer Credit Control (Overdue Dealers)";
export const CHEQUE_REGISTER_LIST_LABEL = "Cheque Register List";

//Account Payable >> Inquiry
export const AP_TRANSACTION_INFO_LABEL = "AP Transaction Info"
//Account Payable >> Transactions
export const PAYMENT_VOUCHER_LABEL = "Payment Voucher"
export const AP_PAYMENT_CN_OFFSET_LABEL = "AP Payment/CN Offset"
export const PV_BP_APPROVAL_LIST_LABEL = "PV BP Approval List"
export const SUPPLIER_INVOICE_LABEL = "Supplier Invoice"
export const AP_DEBIT_NOTE_LABEL = "Debit Note"
export const AP_CREDIT_NOTE_LABEL = "Credit Note"
export const AP_CASH_BOOK_RECONCILIATION_LABEL = "Cash Book Reconciliation"
export const AP_BAD_DABT_RELIEF_LABEL = "Bad Debt Relief"
export const AP_BAD_DEBT_RECOVER_LABEL = "Bad Debt Recovery"
//Account Payable >> Processing
export const AP_POSTING_LABEL = "AP Posting"
export const AP_UN_POSTING_LABEL = "AP Un-Posting"
//Account Payable >> Report
export const INPUT_TAX_LISTING_LABEL = "Input Tax Listing";
export const CASH_BOOK_RECON_REPORT_LABEL = "Cash Book Reconciliation Report";
export const SUPPLIER_TRANSACTION_LIST_LABEL = "Supplier Transaction List";
export const SUPPLIER_AGING_LABEL = "Supplier Aging";
export const SUPPLIER_LEDGER_LABEL = "Supplier Ledger";
export const GST_PURCHASE_LISTING_LABEL = "GST Purchase Listing";
export const ADJUSTMENT_LISTING_LABEL = "Adjustment Listing";
export const PV_BULK_PAYMENT_STATUS_REPORT_LABEL = "PV Bulk Payment Status Report";
export const PAYMENT_VOUCHER_REPORT_LABEL = "Payment Voucher Report";
export const GST_BAD_DEBT_LISTING_LABEL = "GST Bad Debt Listing";
export const SUPPLIER_SUMMARY_TRANSACTION_LIST_LABEL = "Supplier Summary Transaction List";

//HR Lite >> Maintenance
export const TRAVEL_REQUISITION_LABEL = "Travel Requisition"
export const CLAIM_FORM_LABEL = "Claim Form"
export const CONFIGURATION_LABEL = "Configuration"

//Dealer Portal >> Maintenance
export const BRANCH_CONTROL_LABEL = "Branch Control"
export const BRANCH_CUSTOMER_CONTROL_LABEL = "Branch Customer Control"
export const ITEM_CONTROL_LABEL = "Item Control"
export const BRAND_ASSIGNMENT_LABEL = "Brand Assignment"
export const BRANCH_STATE_CONTROL_LABEL = "Branch State Control"
export const HOLIDAY_SCHEDULE_LABEL = "Holiday Schedule"
//Dealer Portal >> Setting
export const MEDIA_LABEL = "Media"
export const LOGIN_PAGE_SETTINGS = "Login Page Settings"
//Dealer Portal >> Control Setup
export const GLOBAL_CONTROL_SETUP_LABEL = "Global Control Setup"
export const DISABLE_ONLINE_SO_SETUP_LABEL = "Disable Online SO Setup"
//Dealer Portal >> Inquiry
export const SALES_ORDER_HISTORY_LABEL = "Sales Order History"
export const OFFLINE_PAYMENT_HISTORY_LABEL = "Offline Payment History"
export const CREDIT_PAYMENT_HISTORY_LABEL = "Credit Payment History"
export const CUSTOMER_CREDIT_CHECKING_LABEL = "Customer Credit Checking"
//Dealer Portal >> Transactions
export const SKIP_FPX_PAYMENT_LABEL = "Skip FPX Payment"
export const VERIFY_OFFLINE_PAYMENT_LABEL = "Verify Offline Payment"



//Samsung >> Maintenance
export const SAMSUNG_BRANCH_STATE_LABEL = "Branch State Control"
export const SAMSUNG_BRANCH_CONTROL_LABEL = "Branch Control"
export const SAMSUNG_CATALOGUE_UPDATE_APPROVAL_LABEL = "Catalogue Update Approval"
import * as Labels from 'utils/labels.js';

export const ModuleNav = [
  {
    icon: "icon-user-square",
    label: "Superadmin",
    content: [
      {
        label: "User Access",
        to: "#/dashboard/super_admin/user_access",
        tabs: [
          Labels.USER_GROUP_LABEL,
          Labels.USER_ACCOUNT_ACCESS_LABEL,
          Labels.DUPLICATE_USER_PERMISSION_LABEL,
        ]
      },
      {
        label: "Modules",
        to: "#/dashboard/super_admin/modules",
        module: "Modules"
      },
    ],
  },
  {
    icon: "icon-user-square",
    label: "Dashboard",
    content: [
      {
        label: "Main",
        to: "#/dashboard/main",
        tabs: [
          Labels.PRINT_JOB_STATUSES_LABEL,
          Labels.APPROVAL_NOTIFICATIONS,
          Labels.QUICK_ACCESS,
        ]
      }
    ]
  },
  {
    icon: "icon-passcode",
    label: "System Setup",
    content: [
      {
        label: "Organisation",
        to: "#/dashboard/system_setup/organisation",
        tabs: [
          Labels.ORGANISATION_TAB_LABEL,
          Labels.BRANCH_TAB_LABEL,
        ]
      },
      {
        label: "Employee",
        to: "#/dashboard/system_setup/employee",
        tabs: [
          Labels.JOB_POSITION_LABEL,
          Labels.EMPLOYEE_MASTER_LABEL,
          Labels.CUSTOMER_REASSIGNMENT_LABEL,
          Labels.ITEM_BRAND_AND_ITEM_GROUP_ASSIGNMENT_LABEL,
          Labels.SUPERIOR_ASSIGNMENT_LABEL,
        ]
      },
      {
        label: "Customer",
        to: "#/dashboard/system_setup/customer",
        tabs: [
          Labels.CUSTOMER_MASTER_LABEL,
          Labels.CUSTOMER_TYPE_LABEL,
          Labels.CUSTOMER_GROUP_LABEL,
          Labels.CUSTOMER_OUTLET_LABEL,
          Labels.BRAND_TIER_LABEL,
        ]
      },
      {
        label: "Supplier",
        to: "#/dashboard/system_setup/supplier",
        tabs: [
          Labels.SUPPLIER_TYPE,
          Labels.SUPPLIER_GROUP,
          Labels.SUPPLIER_MASTER,
          Labels.GENERAL_PAYEE_MASTER,
        ]
      },
      {
        label: "Bank",
        to: "#/dashboard/system_setup/bank",
        tabs: [
          Labels.BANK_GROUP_TAB_LABEL,
          Labels.BANK_BRANCH_TAB_LABEL,
          Labels.BANK_ACCOUNT_TAB_LABEL,
          Labels.PAYMENT_MODE,
          Labels.PRODUCT_GROUP_TAB_LABEL,
          Labels.BULK_PAYMENT_SETUP_TAB_LABEL,
        ]
      },
      {
        label: "Setting",
        to: "#/dashboard/system_setup/settings",
        tabs: [
          Labels.HEADER_FOOTER_TAB_LABEL,
          Labels.TNC_TAB_LABEL,
          Labels.TAX_CODE_LABEL,
          Labels.TAX_TYPE_LABEL,
          Labels.UOM_LABEL,
          Labels.DELIVERY_TYPE_LABEL,
          Labels.CREDIT_TERMS_LABEL,
          Labels.PRINTER_LABEL,
          Labels.APPROVAL_SETTINGS_LABEL,
        ]
      },
      {
        label: "Control Setup",
        to: "#/dashboard/system_setup/control_setup",
        tabs: [
          Labels.INVENTORY_CONTROL_LABEL,
          Labels.SO_CONTROL_LABEL,
          Labels.AR_CONTROL_LABEL,
          Labels.AP_CONTROL_LABEL,
          Labels.GL_CONTROL_LABEL,
          Labels.DEALER_PORTAL_CONTROL_LABEL,
        ]
      },
      {
        label: "Report",
        to: "#/dashboard/system_setup/report",
        tabs: [
          Labels.GST_TAX_LISTING,
          Labels.CUSTOMER_MASTER_LIST,
          Labels.TERM_LIMIT,
          Labels.SUPPLIER_MASTER_LISTING,
          Labels.GENERAL_PAYEE_MASTER_LISTING,
        ]
      },
    ],
  },
  {
    icon: "icon-arrow-circle-broken-right",
    label: "Purchase",
    content: [
      {
        label: "Transactions",
        to: "#/dashboard/purchase/transactions",
        tabs: [
          Labels.PO_LABEL,
          Labels.GRN_LABEL,
          Labels.GRO_LABEL,
          Labels.UPDATE_GRN_STATUS_LABEL,
        ]
      },
    ],
  },
  {
    icon: "icon-home",
    label: "Inventory",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/inventory/Maintenance",
        tabs: [
          Labels.ITEM_TAB_LABEL,
          Labels.ITEM_BRAND_TAB_LABEL,
          Labels.ITEM_GROUP_TAB_LABEL,
          Labels.ITEM_MODEL_TAB_LABEL,
          Labels.ITEM_ACCESSORY_TAB_LABEL,
          Labels.PRODUCT_EXCHANGE_LABEL,
          Labels.PRODUCT_REPLACEMENT_LABEL,
          Labels.ITEM_RECLASSIFICATION_LABEL,
          Labels.PHYSICAL_ADJUSTMENT_LABEL,
        ]
      },
      {
        label: "Inquiry",
        to: "#/dashboard/inventory/inquiry",
        tabs: [
          Labels.INVENTORY_STATUS_LABEL,
          Labels.PRODUCT_STATUS_LABEL,
        ]
      },
      {
        label: "Transactions",
        to: "#/dashboard/inventory/transactions",
        tabs: [
          Labels.ITEM_ON_LOAN_LABEL,
          Labels.ITEM_ON_LOAN_SALES_LABEL,
          Labels.ITEM_ON_LOAN_RETURN_LABEL,
          Labels.ITEM_RESERVE_LABEL,
          Labels.LOCATION_TRANSFER_OUT_LABEL,
          Labels.LOCATION_TRANSFER_IN_LABEL,
        ]
      },
      {
        label: "Processing",
        to: "#/dashboard/inventory/processing",
        tabs: [
          Labels.IN_POSTING_LABEL,
          Labels.IN_UNPOSTING_LABEL,
        ]
      },
      {
        label: "Reports",
        to: "#/dashboard/inventory/report",
        tabs: [
          Labels.TRANSIT_REPORT_LTO_LABEL,
          Labels.INVENTORY_MOVEMENT_REPORT_LABEL,
          Labels.INVENTORY_MOVEMENT_REPORT_EXTRA_LABEL,
          Labels.INVENTORY_MOVEMENT_REPORT_CONSOLIDATE_LABEL,
          Labels.INVENTORY_MOVEMENT_REPORT_WITH_COST_LABEL,
          Labels.STOCK_TAKE_REPORT_LABEL,
          Labels.ITEM_ON_LOAN_LISTING_LABEL,
          Labels.ITEM_MASTER_DETAIL_LISTING_LABEL,
          Labels.STOCK_CLOSING_REPORT_LABEL,
          Labels.ITEM_RESERVATION_LABEL,
          Labels.OUTSTANDING_GRN_LISTING_LABEL,
          Labels.INVENTORY_JOURNAL_LISTING_LABEL,
          Labels.GRO_CN_OUTSTANDING_LIST_LABEL,
          Labels.GRN_DETAIL_REPORT_LABEL,
          Labels.STOCK_PSQ_INVENTORY_LABEL,
        ]
      },
    ],
  },
  {
    icon: "icon-tag",
    label: "Sales",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/sales/maintenance",
        tabs: [
          Labels.COST_CONTROL_LABEL,
          Labels.RETURN_REASON_LABEL,
          Labels.REBATE_LABEL,
          Labels.TAX_LABEL,
          Labels.CURRENCY_EXCHANGE_RATE_LABEL,
          Labels.ORDER_SOURCE_LABEL,
          Labels.BUNDLE_PACKAGE_LABEL,
          Labels.INVOICE_REASSIGNMENT_LABEL,
          Labels.SO_SETUP_LABEL,
          Labels.SO_PRINTER_CONTROL_LABEL
        ]
      },
      {
        label: "Inquiry",
        to: "#/dashboard/sales/inquiry",
        tabs: [
          Labels.SO_STATUS_LABEL,
          Labels.DEALER_TRANSFER_LOG_LABEL,
          Labels.DEALER_TERM_AND_CREDIT_LABEL,
          Labels.CREDIT_REVIEW_HISTORY_LABEL,
        ]
      },
      {
        label: "Transactions",
        to: "#/dashboard/sales/transactions",
        tabs: [
          Labels.PRE_SALES_QUOTATION_LABEL,
          Labels.SALES_QUOTATION_LABEL,
          Labels.SALES_ORDER_LABEL,
          Labels.SALES_ORDER_BRANCH_LABEL,
          Labels.SALES_RETURN_LABEL,
          Labels.CREDIT_REVIEW_LABEL,
          Labels.PRICE_REVIEW_LABEL,
          Labels.INVOICE_MANUAL_LABEL,
          Labels.ITEM_PICKING_LABEL,
          Labels.ITEM_PICKING_BRANCH_LABEL,
        ]
      },
      {
        label: "Reports",
        to: "#/dashboard/sales/reports",
        tabs: [
          Labels.PSQ_LABEL,
          Labels.SALES_DISTRIBUTION_LABEL,
          Labels.DAILY_SUMMARY_SALES_LISTING_LABEL,
          Labels.PSI_REPORT_LABEL,
          Labels.SALES_IMEI_REPORT_LABEL,
          Labels.SALES_BY_CUSTOMER_LABEL,
          Labels.SO_PROCESS_DETAIL_LABEL,
          Labels.SALES_PAYMENT_DURATION_REPORT_LABEL,
          Labels.IMEI_LISTING_LABEL,
          Labels.CREDIT_TERMS_LIMIT_REVIEW_REPORT_LABEL,
          Labels.SALES_BY_MODEL_LABEL,
          Labels.SALES_DISTRIBUTION_BY_MODEL_LABEL,
          Labels.SAMSUNG_SALES_DISTRIBUTION_LABEL,
        ]
      }
    ],
  },
  {
    icon: "icon-commission",
    label: "Commission",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/commission/maintenance",
        tabs: [
          Labels.COMMISSION_SETUP_LABEL,
          Labels.COMMISSION_SCHEDULE_LABEL,
          Labels.COMMISSION_MODEL_GROUP_LABEL,
        ]
      },
      {
        label: "Reports",
        to: "#/dashboard/commission/reports",
        tabs: [
          Labels.COMMISSION_DETAIL_LABEL,
          Labels.PAYMENT_COLLECTED_LABEL,
          Labels.ACTIVE_DEALER_DETAIL_LABEL,
          Labels.ACTIVE_DEALER_BY_BRAND_LABEL,
        ]
      }
    ],
  },
  {
    icon: "icon-truck",
    label: "Delivery",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/delivery/maintenance",
        tabs: [
          Labels.DELIVERY_REASSIGNMENT_LABEL,
          Labels.COURIER_MASTER_LABEL,
          Labels.POSTCODE_CONTROL,
        ]
      },
      {
        label: "Inquiry",
        to: "#/dashboard/delivery/inquiry",
        tabs: [
          Labels.DELIVERY_STATUS_LABEL,
          Labels.DESPATCH_DO_INFO_LABEL,
          Labels.COURIER_DO_INFO_LABEL,
        ]
      },
      {
        label: "Transactions",
        to: "#/dashboard/delivery/transactions",
        tabs: [
          Labels.CONSIGNMENT_NOTE_LABEL,
          Labels.MANUAL_CONSIGNMENT_LABEL,
          Labels.DESPATCH_ASSIGNMENT_LABEL,
          Labels.COURIER_COLLECTION_LABEL,
          Labels.PARCEL_NOTE_LABEL,
        ]
      },
      {
        label: "Reports",
        to: "#/dashboard/delivery/report",
        tabs: [
          Labels.MCMC_REPORT_LABEL,
          Labels.DO_DETAIL_REPORT_LABEL,
          Labels.DO_DETAIL_REPORT_BY_ITEM_LABEL,
          Labels.PARCEL_DETAIL_LOG_LABEL,
          Labels.HIGH_VALUE_ITEM_CONTROL_LABEL,
          Labels.COURIER_DETAIL_REPORT_LABEL,
          Labels.DO_PRODUCT_LABEL,
        ]
      },
    ],
  },
  {
    icon: "icon-file",
    label: "General Ledger",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/generalLedger/maintenance",
        tabs: [
          Labels.SERVICE_ITEM_LABEL,
          Labels.GST_LABEL,
          Labels.SST_LABEL,
          Labels.COA_LABEL,
          Labels.ACCOUNT_ANALYSIS_LABEL,
          Labels.ACCOUNT_GROUP_LABEL,
          Labels.FINANCIAL_YEAR_LABEL,
        ]
      },
      {
        label: "Transactions",
        to: "#/dashboard/generalLedger/transactions",
        tabs: [
          Labels.GENERAL_PAYMENT_LABEL,
          Labels.GENERAL_JOURNAL_LABEL,
          Labels.GP_BP_APPROVAL_LIST_LABEL,
        ]
      },
      {
        label: "Processing",
        to: "#/dashboard/generalLedger/processing",
        tabs: [
          Labels.GL_POSTING_LABEL,
          Labels.GL_UNPOSTING_LABEL,
          Labels.YEAR_END_PROCESSING_LABEL,
          Labels.PRIOR_YEAR_END_CLOSING_ADJUSTMENT_LABEL,
          Labels.INVENTORY_LTO_POSTING_LABEL,
          Labels.INVENTORY_LTO_UNPOSTING_LABEL
        ]
      },
      {
        label: "Reports",
        to: "#/dashboard/generalLedger/report",
        tabs: [
          Labels.TAX_LISTING_LABEL,
          Labels.GST_SUMMARY_REPORT_LABEL,
          Labels.GL_GENERAL_LEDGER_LABEL,
          Labels.GL_JOURNAL_LIST_LABEL,
          Labels.TRIAL_BALANCE_LABEL,
          Labels.GST_GL_LISTING_LABEL,
          Labels.GP_BULK_PAYMENT_STATUS_REPORT_LABEL,
          Labels.FINANCIAL_STATEMENT_REPORT_LABEL,
          Labels.CHART_OF_ACCOUNT_LABEL,
          Labels.GP_SUMMARY_TRANSACTION_LIST_LABEL,
        ]
      },
    ],
  },
  {
    icon: "icon-file-plus",
    label: "Account Receivable",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/receivable/maintenance",
        tabs: [
          Labels.CN_TYPE_LABEL,
          Labels.DN_TYPE_LABEL,
        ]
      },
      {
        label: "Inquiry",
        to: "#/dashboard/receivable/inquiry",
        tabs: [
          Labels.AR_TRANSACTION_INFO_LABEL,
        ]
      },
      {
        label: "Transactions",
        to: "#/dashboard/receivable/transactions",
        tabs: [
          Labels.AR_INVOICE_LABEL,
          Labels.AR_INVOICE_SST_LABEL,
          Labels.BATCH_CN_REQUISITION_LABEL,
          Labels.BATCH_DN_REQUISITION_LABEL,
          Labels.CN_REQUISITION_LABEL,
          Labels.CREDIT_NOTE_LABEL,
          Labels.DN_REQUISITION_LABEL,
          Labels.DEBIT_NOTE_LABEL,
          Labels.AR_RECEIPT_LABEL,
          Labels.ADVANCED_OFFSET_LABEL,
          Labels.CASH_BOOK_RECONCILIATION_LABEL,
          Labels.BAD_DABT_RELIEF_LABEL,
          Labels.BAD_DEBT_RECOVER_LABEL,
        ]
      },
      {
        label: "Processing",
        to: "#/dashboard/receivable/processing",
        tabs: [
          Labels.AR_POSTING_LABEL,
          Labels.AR_UN_POSTING_LABEL,
        ]
      },
      {
        label: "Reports",
        to: "#/dashboard/receivable/report",
        tabs: [
          Labels.CUSTOMER_STATEMENT_LABEL,
          Labels.CUSTOMER_STATEMENT_OFFSET_LABEL,
          Labels.CUSTOMER_TRANSACTION_NOTE_LABEL,
          Labels.CUSTOMER_AGING_LABEL,
          Labels.CP_58_REPORT_LABEL,
          Labels.CN_APPROVAL_LIST_LABEL,
          Labels.CN_STATUS_REPORT_LABEL,
          Labels.DN_APPROVAL_LIST_LABEL,
          Labels.DN_STATUS_REPORT_LABEL,
          Labels.CASH_BOOK_RECON_REPORT_LABEL,
          Labels.GST_SUPPLY_LISTING_LABEL,
          Labels.OUTPUT_TAX_LISTING_LABEL,
          Labels.CUSTOMER_TRANSACTION_LIST_LABEL,
          Labels.CUSTOMER_SUMMARY_TRANSACTION_LIST_LABEL,
          Labels.CUSTOMER_OVERDUE_LABEL,
          Labels.ROUDING_ADJ_LIST_LABEL,
          Labels.CUSTOMER_LEDGER_LABEL,
          Labels.GST_BAD_DEBT_LISTING_LABEL,
          Labels.CN_DN_LISTING_LABEL,
          Labels.DEALER_CREDIT_CONTORL_LABEL,
          Labels.CHEQUE_REGISTER_LIST_LABEL,
        ]
      }
    ],
  },
  {
    icon: "icon-file-minus",
    label: "Account Payable",
    content: [
      {
        label: "Inquiry",
        to: "#/dashboard/payable/inquiry",
        tabs: [
          Labels.AP_TRANSACTION_INFO_LABEL,
        ]
      },
      {
        label: "Transactions",
        to: "#/dashboard/payable/transactions",
        tabs: [
          Labels.PAYMENT_VOUCHER_LABEL,
          Labels.AP_PAYMENT_CN_OFFSET_LABEL,
          Labels.PV_BP_APPROVAL_LIST_LABEL,
          Labels.SUPPLIER_INVOICE_LABEL,
          Labels.AP_DEBIT_NOTE_LABEL,
          Labels.AP_CREDIT_NOTE_LABEL,
          Labels.AP_CASH_BOOK_RECONCILIATION_LABEL,
          Labels.AP_BAD_DABT_RELIEF_LABEL,
          Labels.AP_BAD_DEBT_RECOVER_LABEL,
        ]
      },
      {
        label: "Processing",
        to: "#/dashboard/payable/processing",
        tabs: [
          Labels.AP_POSTING_LABEL,
          Labels.AP_UN_POSTING_LABEL,
        ]
      },
      {
        label: "Reports",
        to: "#/dashboard/payable/report",
        tabs: [
          Labels.INPUT_TAX_LISTING_LABEL,
          Labels.CASH_BOOK_RECON_REPORT_LABEL,
          Labels.SUPPLIER_TRANSACTION_LIST_LABEL,
          Labels.SUPPLIER_AGING_LABEL,
          Labels.SUPPLIER_LEDGER_LABEL,
          Labels.GST_PURCHASE_LISTING_LABEL,
          Labels.ADJUSTMENT_LISTING_LABEL,
          Labels.PV_BULK_PAYMENT_STATUS_REPORT_LABEL,
          Labels.PAYMENT_VOUCHER_REPORT_LABEL,
          Labels.GST_BAD_DEBT_LISTING_LABEL,
          Labels.SUPPLIER_SUMMARY_TRANSACTION_LIST_LABEL,
        ]
      },
    ],
  },
  {
    icon: "icon-user-square",
    label: "HR Lite",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/hr_lite/maintenance",
        tabs: [
          Labels.TRAVEL_REQUISITION_LABEL,
          Labels.CLAIM_FORM_LABEL,
          Labels.CONFIGURATION_LABEL,
        ]
      },
    ],
  },
  {
    icon: "icon-setting-3",
    label: "Dealer Portal",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/dealer_portal/maintenance",
        tabs: [
          Labels.BRANCH_CONTROL_LABEL,
          Labels.BRANCH_CUSTOMER_CONTROL_LABEL,
          Labels.ITEM_CONTROL_LABEL,
          Labels.BRAND_ASSIGNMENT_LABEL,
          Labels.BRANCH_STATE_CONTROL_LABEL,
          Labels.HOLIDAY_SCHEDULE_LABEL,
        ]
      },
      {
        label: "Setting",
        to: "#/dashboard/dealer_portal/setting",
        tabs: [
          Labels.MEDIA_LABEL,
          Labels.LOGIN_PAGE_SETTINGS,
        ]
      },
      {
        label: "Control Setup",
        to: "#/dashboard/dealer_portal/control_setup",
        tabs: [
          Labels.GLOBAL_CONTROL_SETUP_LABEL,
          Labels.DISABLE_ONLINE_SO_SETUP_LABEL,
        ]
      },
      {
        label: "Inquiry",
        to: "#/dashboard/dealer_portal/inquiry",
        tabs: [
          Labels.SALES_ORDER_HISTORY_LABEL,
          Labels.OFFLINE_PAYMENT_HISTORY_LABEL,
          Labels.CREDIT_PAYMENT_HISTORY_LABEL,
          Labels.CUSTOMER_CREDIT_CHECKING_LABEL,
        ]
      },
      {
        label: "Transactions",
        to: "#/dashboard/dealer_portal/transactions",
        tabs: [
          Labels.SKIP_FPX_PAYMENT_LABEL,
          Labels.VERIFY_OFFLINE_PAYMENT_LABEL,
        ]
      },
    ],
  },
  {
    icon: "icon-smartphone",
    label: "Samsung",
    content: [
      {
        label: "Maintenance",
        to: "#/dashboard/samsung/maintenance",
        tabs: [
          Labels.SAMSUNG_BRANCH_STATE_LABEL,
          Labels.SAMSUNG_BRANCH_CONTROL_LABEL,
        ]
      }
    ],
  },
  {
    icon: "icon-settings",
    label: "System Report",
    content: [
      {
        label: "Audit Log",
        to: "#/dashboard/system_report/audit_log",
        module: "Audit Log",
      },
    ],
  },
];


export const ImpersonateModuleNav = structuredClone(ModuleNav)
  .filter(nav => !["Superadmin", "Dashboard"].includes(nav.label))
  .map(nav => {
    nav.content = nav.content.map(content => {
      content.to = content.to?.replace("#/", "#/admin-impersonate/")
      return content
    })
    return nav
  });

export const AccountNav = [
  {
    icon: "icon-log-out",
    label: "Logout",
  },
];
